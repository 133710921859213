<template>
	<div :style="getAppStyle()" class='BMadbreak1' :title="getTitle()" >
		<div style="font-size: 8pt; font-weight: normal;" :class="getClass()">
					
			<GRate :value="daySlot.capacity-daySlot.available" :color="getBGColor('#eaa', '#a44')" :max="daySlot.capacity" style="width: 100%;">
				 <div :style="'color: '+getFG()+';text-align: center; white-space: nowrap; display: inline-block; width: 100% !important;'">
					{{show( daySlot )}}
				</div>
			</GRate>
		</div>
   </div>
</template>
<script>
import { getAppStyle, isDarkMode, getFG,getBGColor } from '@/AppStyle.js';
import GRate from '@/components/GFill';
import { formatPercentInt, format } from '@/Numbers.js';
export default {
  props: {
	  daySlot: { type: Object },
	  display: { type: String, default: 'spots' },
	  forDuration: { type: Number, default: 10 },
  },
 components : {
	 GRate
  },
  data () {
    return {
    	getAppStyle,getFG, getBGColor
    }
  },
 methods: {
   getClass()
   {
	
	if ( this.daySlot.available < 0)
	{
		return "hatchMinus"+((isDarkMode())?"DM":"");
	}
	if ( this.forDuration > this.daySlot.available )
	{
		return "hatchOver"+((isDarkMode())?"DM":"");
	}
	return "";
   },	
   show( daySlot)
   {
		if ( this.display == 'spots')
		{
			return daySlot.spotCount.totalSpots;
		}
		if ( this.display == 'free')
		{
			return daySlot.available;
		}
		if ( this.display == 'booked')
		{
			return daySlot.capacity - daySlot.available;
		}
		if ( this.display == 'capacity')
		{
			return daySlot.capacity;
		}
		if ( this.display == 'price')
		{
			return daySlot.price;
		}
		if ( this.display == 'pricegroup')
		{
			return daySlot.pricegroup;
		}
		if ( this.display == 'booked ratio')
		{
			return formatPercentInt((daySlot.capacity - daySlot.available) / daySlot.capacity);
		}
		if ( this.display == 'free ratio')
		{
			return formatPercentInt(daySlot.available / daySlot.capacity);
		}
		if ( this.display == 'budget')
		{
			return format(daySlot.spotCount.totalBudget);
		}
		if ( this.display == 'avg price')
		{
			if ( daySlot.capacity != daySlot.available)
			{
				return format(this.forDuration * daySlot.spotCount.totalBudget / (daySlot.capacity - daySlot.available));
			}
			return "-"
		}
		return daySlot.available
   },
   getTitle()
   {
		let title = "";
		title += this.daySlot.program+", Ratecard: "+this.daySlot.price+ ", Available: "+ this.daySlot.available+"sec"
		return title;
   }
 }
} 
</script>
<style lang="scss" scoped>
.BMadbreak1 {
	display: inline-flex; 
	width: 100%;
	max-height: 12pt;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 0pt;
    height: 100%;

}


.hatchOver
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 5px);
}
.hatchMinus
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 3px);
}


.hatchOverDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 5px);
}
.hatchMinusDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 3px);
}

</style>